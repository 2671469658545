<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-container fluid>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="$emit('close-action')"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Opciones para generación del reporte</v-toolbar-title>
            </v-toolbar>
            <v-list
              three-line
              subheader
              class="px-10"
            >
              <div class="my-5 text-h4">
                <span class="grey--text text--darken-1">
                  Use los controles para poder filtrar la información. Ningún parámetro es obligatorio, pero debe utilizar al menos uno. Todos los filtros que utilice se concatenarán para generar el resultado.
                </span>
              </div>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Fecha de Registro</v-list-item-title>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-menu
                        v-model="inicioIngresoPanel"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="inicioIngreso"
                            label="Desde"
                            hint="DD/MM/YYYY"
                            persistent-hint
                            clearable
                            prepend-icon="mdi-calendar-month"
                            v-bind="attrs"
                            :rules="[
                              (v) =>
                                !finIngresoFnBackup ||
                                inicioIngresoFnBackup <= finIngresoFnBackup ||
                                'La fecha de Inicio debe ser menor a la fecha de Fin',
                            ]"
                            @blur="inicioIngresoFn = parseDate(inicioIngreso)"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="inicioIngresoFn"
                          locale="es"
                          show-current="false"
                          @input="inicioIngresoPanel = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-menu
                        v-model="finIngresoPanel"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="finIngreso"
                            label="Hasta"
                            hint="DD/MM/YYYY"
                            persistent-hint
                            clearable
                            prepend-icon="mdi-calendar-month"
                            v-bind="attrs"
                            :rules="[
                              (v) =>
                                !inicioIngresoFn ||
                                finIngresoFnBackup >= inicioIngresoFnBackup ||
                                'La fecha de Fin debe ser mayor a la fecha de Inicio',
                            ]"
                            @blur="finIngresoFn = parseDate(finIngreso)"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="finIngresoFn"
                          locale="es"
                          show-current="false"
                          @input="finIngresoPanel = false"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-row class="my-3">
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="mt-lg-3"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Nombre</v-list-item-title>
                      <v-list-item-subtitle>Escriba una porción del nombre, o el nombre completo, por el que desee filtrar</v-list-item-subtitle>
                      <v-text-field
                        v-model="nombre"
                        label="Nombre"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Tipo de Oferta</v-list-item-title>
                      <v-list-item-subtitle>Puede seleccionar uno o más tipos de ofertas</v-list-item-subtitle>
                      <v-autocomplete
                        v-model="tiposOferta"
                        :items="itemsTiposOferta"
                        label="Tipos de ofertas"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        item-text="title"
                        item-value="val"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-list>
            <v-card-actions>
              <v-row
                class="justify-end"
              >
                <v-btn
                  class="mr-4"
                  color="primary"
                  :loading="loading"
                  @click="submit"
                >
                  Generar
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

  <script>
  import Vue from 'vue'

  export default {
    name: 'ModalReportOfertantes',
    props: {
      showDialog: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        notifications: false,
        sound: true,
        widgets: false,
        valid: true,
        itemsTiposOferta: [
          { val: 'Interna', title: 'Interna' },
          { val: 'Externa', title: 'Externa' },
          { val: 'Otros', title: 'Otros' },
        ],
        inicioIngresoFn: new Date().toISOString().substr(0, 10),
        inicioIngresoPanel: false,
        finIngresoFn: new Date().toISOString().substr(0, 10),
        finIngresoPanel: false,
        inicioIngresoFnBackup: null,
        finIngresoFnBackup: null,
        // Parámetros reporte
        inicioIngreso: null,
        finIngreso: null,
        nombre: null,
        tiposOferta: null,
      }
    },
    computed: {
      loading: {
        get () {
          return this.$store.state.ofertantes.loading
        },
      },
    },
    watch: {
      inicioIngresoFn (val) {
        this.inicioIngreso = this.formatDate(this.inicioIngresoFn)
        this.inicioIngresoFnBackup = val
      },
      finIngresoFn (val) {
        this.finIngreso = this.formatDate(this.finIngresoFn)
        this.finIngresoFnBackup = val
      },
    },
    methods: {
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      forceFileDownload (response, nombre) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nombre)
        document.body.appendChild(link)
        link.click()
      },
      async submit () {
        if (
          this.inicioIngreso === null &&
          this.finIngreso === null &&
          this.nombre === null &&
          this.tiposOferta === null
        ) {
          Vue.notify({
            group: 'loggedIn',
            text: 'Es necesario utilizar al menos uno de los filtros',
            type: 'red accent-2',
            duration: 5000,
          })
        } else if (this.$refs.form.validate()) {
          const datos = {
            inicio_ingreso: this.inicioIngreso,
            fin_ingreso: this.finIngreso,
            nombre: this.nombre,
            tipos_oferta: this.tiposOferta,
          }

          // console.log(datos)

          var response = await this.$store.dispatch('ofertantes/reportOfertantes', datos)
          // console.log(response)
          this.forceFileDownload(response, 'ofertantes_reporte.pdf')
        } else {
          console.log('invalid')
          Vue.notify({
            group: 'loggedIn',
            text: 'Hay errores en el rango de fechas seleccionado',
            type: 'red accent-2',
            duration: 5000,
          })
        }
      },
    },
  }
  </script>

  <style>
  .dialog-bottom-transition-enter-active,
  .dialog-bottom-transition-leave-active {
    transition: transform 0.2s ease-in-out;
  }
  </style>
