var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close-action')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Opciones para generación del reporte")])],1),_c('v-list',{staticClass:"px-10",attrs:{"three-line":"","subheader":""}},[_c('div',{staticClass:"my-5 text-h4"},[_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v(" Use los controles para poder filtrar la información. Ningún parámetro es obligatorio, pero debe utilizar al menos uno. Todos los filtros que utilice se concatenarán para generar el resultado. ")])]),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Fecha de Registro")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Desde","hint":"DD/MM/YYYY","persistent-hint":"","clearable":"","prepend-icon":"mdi-calendar-month","rules":[
                            function (v) { return !_vm.finIngresoFnBackup ||
                              _vm.inicioIngresoFnBackup <= _vm.finIngresoFnBackup ||
                              'La fecha de Inicio debe ser menor a la fecha de Fin'; } ]},on:{"blur":function($event){_vm.inicioIngresoFn = _vm.parseDate(_vm.inicioIngreso)}},model:{value:(_vm.inicioIngreso),callback:function ($$v) {_vm.inicioIngreso=$$v},expression:"inicioIngreso"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.inicioIngresoPanel),callback:function ($$v) {_vm.inicioIngresoPanel=$$v},expression:"inicioIngresoPanel"}},[_c('v-date-picker',{attrs:{"locale":"es","show-current":"false"},on:{"input":function($event){_vm.inicioIngresoPanel = false}},model:{value:(_vm.inicioIngresoFn),callback:function ($$v) {_vm.inicioIngresoFn=$$v},expression:"inicioIngresoFn"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hasta","hint":"DD/MM/YYYY","persistent-hint":"","clearable":"","prepend-icon":"mdi-calendar-month","rules":[
                            function (v) { return !_vm.inicioIngresoFn ||
                              _vm.finIngresoFnBackup >= _vm.inicioIngresoFnBackup ||
                              'La fecha de Fin debe ser mayor a la fecha de Inicio'; } ]},on:{"blur":function($event){_vm.finIngresoFn = _vm.parseDate(_vm.finIngreso)}},model:{value:(_vm.finIngreso),callback:function ($$v) {_vm.finIngreso=$$v},expression:"finIngreso"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.finIngresoPanel),callback:function ($$v) {_vm.finIngresoPanel=$$v},expression:"finIngresoPanel"}},[_c('v-date-picker',{attrs:{"locale":"es","show-current":"false"},on:{"input":function($event){_vm.finIngresoPanel = false}},model:{value:(_vm.finIngresoFn),callback:function ($$v) {_vm.finIngresoFn=$$v},expression:"finIngresoFn"}})],1)],1)],1)],1)],1),_c('v-row',{staticClass:"my-3"},[_c('v-col',{staticClass:"mt-lg-3",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Nombre")]),_c('v-list-item-subtitle',[_vm._v("Escriba una porción del nombre, o el nombre completo, por el que desee filtrar")]),_c('v-text-field',{attrs:{"label":"Nombre"},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Tipo de Oferta")]),_c('v-list-item-subtitle',[_vm._v("Puede seleccionar uno o más tipos de ofertas")]),_c('v-autocomplete',{attrs:{"items":_vm.itemsTiposOferta,"label":"Tipos de ofertas","chips":"","clearable":"","deletable-chips":"","multiple":"","item-text":"title","item-value":"val"},model:{value:(_vm.tiposOferta),callback:function ($$v) {_vm.tiposOferta=$$v},expression:"tiposOferta"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Generar ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }