<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      color="light-blue"
      icon="mdi-account-tie"
      title="Ofertantes"
      class="px-5 py-3"
    >
      <v-data-table
        v-if="ofertanteslist"
        :headers="headers"
        :items="ofertantes"
        :search="search"
        :loading="loading"
        class="elevation-1 mb-10"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="primary"
              fab
              dark
              small
              class="mb-2 mr-3"
              @click="addOfertantes()"
            >
              <v-icon
                dark
              >
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn
              color="red"
              fab
              dark
              small
              class="mb-2"
              @click="reportModalTrigger()"
            >
              <v-icon dark>
                mdi-file-chart
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.nombre_apellido }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.telefono }}</td>
            <td>
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                small
                color="error"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <addOfertantes
        :edited-item="datos"
        :edited-index="editedIndex"
      />
      <modalReportOfertantes
        :show-dialog="showModalReport"
        @close-action="reportModalTrigger"
      />
    </base-material-card>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import addOfertantes from './addOfertantes.vue'
  import modalReportOfertantes from './modalReportOfertantes.vue'
  export default {
    name: 'OfertantesTable',
    components: {
      addOfertantes,
      modalReportOfertantes,
    },
    data: vm => {
      return {
        search: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        datos: {},
        editedIndex: -1,
        headers: [
          {
            text: 'Nombre y Apellidos',
            align: 'left',
            sortable: true,
            value: 'nombre_apellido',
          },
          {
            text: 'Email',
            align: 'left',
            sortable: true,
            value: 'email',
          },
          {
            text: 'Teléfono',
            align: 'left',
            sortable: true,
            value: 'telefono',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        showModalReport: false,
      }
    },
    computed: {
      ofertanteslist: {
        get () {
          return this.$store.state.ofertantes.ofertanteslist
        },
      },
      ofertantes: {
        get () {
          return this.$store.state.ofertantes.ofertantes
        },
        set (value) {
          return this.$store.commit('ofertantes/setOfertantes', value)
        },
      },
      loading: {
        get () {
          return this.$store.state.ofertantes.loading
        },
      },
    },
    mounted () {
      this.$store.dispatch('ofertantes/getOfertantes')
    },
    methods: {
      addOfertantes () {
        this.datos = {}
        this.editedIndex = -1
        this.$store.commit('ofertantes/setRegister', true)
      },
      editItem (item) {
        this.editedIndex = this.ofertantes.indexOf(item)
        this.datos = Object.assign({}, item)
        this.$store.commit('ofertantes/setRegister', true)
      },
      deleteItem (item) {
        this.$confirm('Está seguro que desea eliminar el registro?', {
          buttonTrueText: 'Si',
          buttonFalseText: 'No',
        })
          .then(res => {
            if (res) {
              this.$store.dispatch('ofertantes/deleteOfertantes', item.id)
                .then((response) => {
                  Vue.notify({
                    group: 'loggedIn',
                    text: response.data.success,
                    type: 'teal accent-3',
                    duration: 5000,
                  })
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                  Vue.notify({
                    group: 'loggedIn',
                    text: 'No se pudo eliminar en este momento',
                    type: 'red accent-2',
                    duration: 5000,
                  })
                },
                )
            }
          })
      },
      reportModalTrigger () {
        this.showModalReport = !this.showModalReport
      },
    },

  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
