<template>
  <v-card
    v-if="ofertantesadd"
    class="mb-10"
  >
    <template>
      <!-- <v-subheader>Datos Personales</v-subheader> -->
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <input
            v-model="editedItem.id"
            type="hidden"
          >
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-menu
                ref="menuFn"
                v-model="menuFn"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.fecha"
                    label="Fecha de Registro"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    prepend-icon="mdi-calendar-month"
                    v-bind="attrs"
                    :rules="[v => !!v || 'Fecha es requerida']"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dateFn"
                  locale="es"
                  show-current="false"
                  @input="insertDate"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-text-field
                v-model="editedItem.dni"
                label="DNI"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-text-field
                v-model="editedItem.nombre_apellido"
                label="Nombre y Apellido"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <vue-tel-input-vuetify
                v-model="editedItem.telefono"
                attach
                solo
                :max-len="15"
                label="Teléfono"
                placeholder="Teléfono"
                valid-characters-only
                mode="international"
                :rules="[v => !!v || 'Teléfono es requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-text-field
                v-model="editedItem.direccion"
                label="Dirección"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-text-field
                v-model="editedItem.postal"
                label="Código Postal"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-text-field
                v-model="editedItem.email"
                label="Email"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-checkbox
                v-model="editedItem.donativo"
                label="Donativo"
                @change="checkboxUpdated"
              />
            </v-col>
            <v-col
              v-if="hayDonativo"
              cols="12"
              sm="4"
              md="4"
            >
              <v-text-field
                v-model="editedItem.cuantia"
                label="Cuantia"
                placeholder="Cuantia"
                suffix="€"
                :rules="cuantiaRule"
                hint="Si va a agregar decimales, deben ser siempre dos. Formato de ejemplo: 1.345,30"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-card-actions class="mt-5">
            <v-row>
              <v-spacer />
              <v-btn
                color="#F6F6F6"
                style="color:black;"
                @click="volver"
              >
                {{ $t("close") }}
              </v-btn>
              <v-btn
                class="mr-4"
                color="primary"
                :loading="loading"
                @click="submit"
              >
                {{ $t("save") }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-container>
    </template>
  </v-card>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'AddOfertantes',
    props: {
      editedItem: {
        type: Object,
        required: true,
      },
      editedIndex: {
        type: Number,
        default: 0,
      },
    },
    data: vm => {
      return {
        tab: null,
        valid: true,
        dateFn: new Date().toISOString().substr(0, 10),
        menuFn: false,
        search: '',
        reset: false,
        hayDonativo: false,
        emailRules: [
          v => !!v || 'El correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        cuantiaRule: [
          v => !!v || 'El campo es requerido',
          v => /^(?:(?:\d{1,3}(\.\d{3})*(,\d{2})?)|\d+,\d{2})$/.test(v) || 'Si va a agregar decimales, deben ser siempre dos. Formato de ejemplo: 1.345,30',
          // No es obligatorio, pero si se ingresa un valor se debe cumplir la regla
          // (v) => (!v || /^(?:(?:\d{1,3}(\.\d{3})*(,\d{2})?)|\d+,\d{2})$/.test(v)) || 'Si va a agregar decimales, deben ser siempre dos. Formato de ejemplo: 1.345,30',
        ],
        /* editedItem: {
          id: 0,
          nombre_apellido: '',
          direccion: '',
          email: '',
          telefono: '',
          fecha: '',
          dni: '',
          postal: '',
          donativo: '',
          cuantia: '',
        }, */
      }
    },
    computed: {
      ofertantesadd: {
        get () {
          return this.$store.state.ofertantes.ofertantesadd
        },
      },
      ofertantes: {
        get () {
          return this.$store.state.ofertantes.ofertantes
        },
      },

      loading: {
        get () {
          return this.$store.state.ofertantes.loading
        },
      },
    },
    watch: {
      /* dateFn (val) {
        this.editedItem.fecha = this.formatDate(this.dateFn)
      }, */
      editedItem (val) {
        if (val.donativo === true) {
          this.hayDonativo = true
        } else {
          this.hayDonativo = false
        }

        if (val.cuantia > 0) {
          const cuantia = val.cuantia
          const formattedNumber = cuantia.toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          val.cuantia = formattedNumber
        }
      },
    },
    async beforeMount () {
      await this.$store.commit('ofertantes/setRegister', false)
    },
    methods: {
      insertDate () {
        this.editedItem.fecha = this.formatDate(this.dateFn)
        this.menuFn = false
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      checkboxUpdated (newValue) {
        this.hayDonativo = newValue
      },
      volver () {
        this.editedIndex = -1
        this.$refs.form.reset()
        this.$store.commit('ofertantes/setRegister', false)
      },
      submit () {
        if (this.$refs.form.validate()) {
          const data = {
            index: this.editedIndex,
            id: this.editedItem.id,
            nombre_apellido: this.editedItem.nombre_apellido,
            direccion: this.editedItem.direccion,
            email: this.editedItem.email,
            telefono: this.editedItem.telefono,
            fecha: this.editedItem.fecha,
            dni: this.editedItem.dni,
            postal: this.editedItem.postal,
            donativo: this.editedItem.donativo,
            cuantia: this.editedItem.cuantia,
          }

          if (this.editedIndex < 0) {
            this.$store.dispatch('ofertantes/addOfertantes', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          } else {
            this.$store.dispatch('ofertantes/updateOfertantes', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
                if (response.data.email) {
                  setTimeout(() => {
                    this.$store.dispatch('logout')
                  }, 3000)
                }
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          }
        }
      },
    },
  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-card__title {
  background-color: aliceblue;
}
.expansionPanel {
  width: 100%;
}
.vue-tel-input {
  height: 50px;
  border: none;
  border-bottom: 1px solid #bbb;
}
</style>
